export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../participants/Index'),
    meta: {
      roles: ['Admin', 'Master', 'Viewer']
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "home" */ '../home/Unauthorized'),
    meta: {
      roles: ['Admin', 'Master', 'Operator', 'Viewer']
    }
  }
]
