import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

export default {
  [types.SET_SETTING] (state, payload) {
    if (payload) {
      state.setting = payload
      convertIntToBoolean(payload, ['has_checkout'])
    }
    state.setting = { ...state.setting, ...payload }
  },
  [types.SET_STATUSLIST] (state, payload) {
    state.statusList = payload
  },
  [types.SET_STATUSSELECT] (state, payload) {
    state.statusSelect = payload
  }
}
