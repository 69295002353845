import services from '../services'
import * as types from './mutation-types'

export const GetAccreditation = ({ commit }) => (
  services.accreditation().then(res => {
    commit(types.SET_SETTING_ACCREDITATION, res.data.data)
  })
)

export const SaveAccreditation = async ({ commit }, obj) => {
  await services.SaveAccreditation(obj).then(res => {
    commit(types.SET_SETTING_ACCREDITATION, res.data.data)
    return res
  })
}
