export default [
  {
    name: 'wa-campaign',
    path: '/wa/campaign',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Campanhas',
      icon: 'dvr',
      label: 'Campanhas',
      roles: ['Admin']
    }
  },
  {
    name: 'wa-campaign-details',
    path: '/wa/campaign/:id',
    component: () => import(/* webpackChunkName: "event" */ './Detail'),
    meta: {
      showNavbar: false,
      title: 'Campanhas - Detalhes da campanha',
      icon: 'dvr',
      label: 'Campanhas - Detalhes da campanha',
      roles: ['Admin']
    }
  }
]

// 'wa-campaign-details
