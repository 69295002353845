import Vue from 'vue'
import routes from './routes'
import Router from 'vue-router'
import VueMeta from 'vue-meta'
import beforeEach from './beforeEach'

Vue.use(Router)
Vue.use(VueMeta, {
  keyName: 'page'
})
const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(beforeEach)

export default router
