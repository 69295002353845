import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_SETTING_CHECKIN, res.data)
  })
)

export const getListCheckin = ({ commit }, params) => (
  services.listCheckin(params).then(res => {
    commit(types.SET_LIST_CHECKIN, res.data)
  })
)

export const resetCheckin = async ({ commit }) => {
  await services.resetCheckin().then(res => {
    return res
  })
}
