import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_PARTICIPANTS, res.data)
  })
)

export const getParticipant = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_PARTICIPANT, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveUser(obj).then(res => {
    commit(types.SET_PARTICIPANT, res.data.data)
    return res
  })
}

export const saveHistoric = async ({ commit }, obj) => {
  await services.saveHistoric(obj).then(res => {
    return res.data
  })
}

export const getAllAttachments = ({ commit }, id) => (
  services.listAttachments(id).then(res => {
    return res.data
  })
)

export const getAllHistoric = ({ commit }, id) => (
  services.listHistorics(id).then(res => {
    return res.data
  })
)

export const getAllMails = ({ commit }, id) => (
  services.listMails(id).then(res => {
    return res.data
  })
)

export const getAllWAMessages = ({ commit }, id) => (
  services.listWAMessages(id).then(res => {
    return res.data
  })
)

export const getAllPayments = ({ commit }, id) => (
  services.listPayments(id).then(res => {
    return res.data.data
  })
)

export const showTransaction = async ({ commit }, obj) => {
  await services.showTransaction(obj).then(res => {
    commit(types.SET_LOGTRANSACTION, res.data.data)
  })
}

export const updateAttachment = async ({ commit }, obj) => {
  await services.updateAttachment(obj).then(res => {
    return res
  })
}
export const uploadAttachment = async ({ commit }, obj) => {
  await services.uploadFile(obj).then(res => {
    return res
  })
}

export const delAttachment = async ({ commit }, id) => {
  await services.deleteAttachment(id).then(res => {
    return res
  })
}

export const sendEmailAttach = async ({ commit }, obj) => {
  await services.sendAttachMail(obj).then(res => {
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const changeType = async ({ commit }, obj) => {
  await services.changeType(obj).then(res => {
    return res
  })
}

export const downloadData = async ({ commit }, params) => {
  return services.exportData(params).then(res => {
    return res.data
  })
}

export const deleteParticipant = async ({ commit }, id) => {
  await services.delParticipant(id).then(res => {
    return res
  })
}
