import http from '@/http'
const URL = '/admin/participants'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const listAttachments = async (id) => {
  const list = (await http.get(URL + `/${id}/attachments`))
  return list
}

export const listHistorics = async (id) => {
  const list = (await http.get(URL + `/${id}/historics?include=operator`))
  return list
}

export const listMails = async (id) => {
  const list = (await http.get(URL + `/${id}/messages`))
  return list
}
export const listWAMessages = async (id) => {
  const list = (await http.get(URL + `/${id}/waMessages`))
  return list
}

export const listPayments = async (id) => {
  const list = (await http.get(URL + `/${id}/orders?include=ticket,cupom`))
  return list
}

export const showTransaction = async (payload) => {
  let one = null
  if (payload.id) {
    one = (await http.get(URL + `/${payload.id}/orders/${payload.orderId}/transaction`))
  }
  return one
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=group,ref,transports,contact,companion,attachments,formData`))
  }
  return one
}

export const saveUser = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}

export const saveHistoric = (payload) => {
  return http.post(URL + `/${payload.participant_id}/historic`, payload)
}

export const uploadFile = (payload) => {
  return http.post(URL + `/attachment`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const sendAttachMail = (payload) => {
  return http.post(URL + `/${payload.id}/email/attachment`, payload)
}

export const updateAttachment = (payload) => {
  if (payload.id) {
    return http.put(URL + `/attachment/${payload.id}`, payload)
  }
  return null
}
export const changeStatus = (payload) => http.put(URL + `/${payload.id}/status`, payload)
export const changeType = (payload) => http.put(URL + `/${payload.id}/type`, payload)

export const exportData = async (params) => {
  const data = (await http.get(URL + '/export', { params: params, responseType: 'blob' }))
  return data
}

export const deleteAttachment = async (id) => http.delete(URL + `/attachment/${id}`)
export const delParticipant = async (id) => http.delete(URL + `/${id}`)

export default {
  list,
  listAttachments,
  listHistorics,
  listMails,
  listWAMessages,
  listPayments,
  showTransaction,
  show,
  saveUser,
  saveHistoric,
  sendAttachMail,
  uploadFile,
  changeStatus,
  changeType,
  exportData,
  deleteAttachment,
  updateAttachment,
  delParticipant
}
