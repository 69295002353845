import Pusher from 'pusher-js'

const key = process.env.VUE_APP_PUSHER_KEY

const socket = new Pusher(key, {
  cluster: 'us2'
})

export default function install (Vue) {
  Object.defineProperties(Vue.prototype, {
    $pusher: {
      get () {
        return socket
      }
    }
  })
}
