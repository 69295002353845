import http from '@/http'
const URL = '/admin/guests'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=tags,group`))
  }
  return one
}

export const saveGuest = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}

export const delGuest = async (id) => http.delete(URL + `/${id}`)
export const changeStatus = (payload) => http.put(URL + `/${payload.id}/status`, payload)

export default {
  list,
  show,
  saveGuest,
  delGuest,
  changeStatus
}
