export default [
  {
    name: 'guests',
    path: '/guests',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Convidados',
      icon: 'dvr',
      label: 'Convidados',
      roles: ['Admin', 'Master', 'Viewer']
    }
  },
  {
    name: 'guest-edit',
    path: '/guests/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Convidado - Edição',
      icon: 'dvr',
      label: 'Convidado - Edição',
      roles: ['Admin', 'Master', 'Viewer']
    }
  },
  {
    name: 'guest-new',
    path: '/guests/new',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Convidado - Adicionar',
      icon: 'dvr',
      label: 'Convidado - Adicionar',
      roles: ['Admin', 'Master', 'Viewer']
    }
  }
]
