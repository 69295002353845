import services from '../services'
import * as types from './mutation-types'

export const GetRSVP = ({ commit }) => (
  services.rsvp().then(res => {
    commit(types.SET_SETTING_RSVP, res.data.data)
  })
)

export const SaveRSVP = async ({ commit }, obj) => {
  await services.SaveRSVP(obj).then(res => {
    commit(types.SET_SETTING_RSVP, res.data.data)
    return res
  })
}
