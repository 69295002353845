import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    room_id: '',
    schedule_date: '',
    start_time: '',
    end_time: '',
    title: '',
    description: ''
  }
})

export default {
  [types.SET_SCHEDULE] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.schedule = payload
  },
  [types.SET_SCHEDULES] (state, payload) {
    state.schedules = payload.data
    state.pagination = payload.meta.pagination
  }
}
