import services from '../services'
import * as types from './mutation-types'

export const GetSettingWA = ({ commit }) => (
  services.GetSettingWA().then(res => {
    commit(types.SET_SETTING_WA, res.data.data)
  })
)

export const saveWA = async ({ commit }, obj) => {
  await services.saveWA(obj).then(res => {
    commit(types.SET_SETTING_WA, res.data.data)
    return res
  })
}
