import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_ADMIN_USERS, res.data)
  })
)

export const getUser = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_ADMIN_USER, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveUser(obj).then(res => {
    commit(types.SET_ADMIN_USER, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const deleteUser = async ({ commit }, obj) => {
  await services.deleteUser(obj).then(res => {
    return res
  })
}
