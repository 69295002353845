export default [
  {
    name: 'login',
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ './pages/Login'),
    meta: {
      roles: []
    }
  }
]
