export default {
  'PARTICIPANT' (state) {
    return state.participant
  },
  'PARTICIPANTS' (state) {
    return state.participants
  },
  'LOGTRANSACTION' (state) {
    return state.logtransaction
  }
}
