export default {
  'SEAT' (state) {
    return state.seat
  },
  'SEATS' (state) {
    return state.seats
  },
  'PARTICIPANTSSEAT' (state) {
    return state.participantsSeat
  }
}
