export default {
  'STATUSSELECT' (state) {
    return state.statusSelect
  },
  'STATUSLIST' (state) {
    return state.statusList
  },
  isCheckOutEnabled (state) {
    return state.setting.has_checkout
  }
}
