import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    name: '',
    title: '',
    description: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    youtube: '',
    website: '',
    photo: null
  }
})

export default {
  [types.SET_SPEAKER] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    if (payload.facebook === null) {
      payload.facebook = ''
    }
    if (payload.instagram === null) {
      payload.instagram = ''
    }
    if (payload.linkedin === null) {
      payload.linkedin = ''
    }
    if (payload.twitter === null) {
      payload.twitter = ''
    }
    if (payload.youtube === null) {
      payload.youtube = ''
    }
    if (payload.website === null) {
      payload.website = ''
    }
    state.speaker = payload
  },
  [types.SET_SPEAKERS] (state, payload) {
    state.speakers = payload.data
    state.pagination = payload.meta.pagination
  }
}
