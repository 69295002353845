import http from '@/http'
const URL = '/admin/settings/wa'

export const GetSettingWA = () => http.get(URL)
export const waTemplates = () => http.get(URL + '/templates')
export const saveWA = (payload) => http.post(URL + `/${payload.id}`, payload)

export default {
  GetSettingWA,
  waTemplates,
  saveWA
}
