import * as types from './mutation-types'

export default {
  [types.SET_SETTING_ACCREDITATION] (state, payload) {
    if (payload) {
      state.accreditation = payload
    }
    state.accreditation = { ...state.accreditation, ...payload }
  }
}
