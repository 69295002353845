export default [
  {
    name: 'setting-tags',
    path: '/settings/checkin',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Controle de acesso',
      icon: 'dvr',
      label: 'Controle de acesso',
      roles: ['Admin']
    }
  }
]
