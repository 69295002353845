import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    name: '',
    avatar: ''
  }
})

export default {
  [types.SET_SETTING_CHECKIN] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.settingcheckin = payload
  },
  [types.SET_SETTINGS_CHECKIN] (state, payload) {
    state.settingscheckin = payload.data
    // state.pagination = payload.meta.pagination
  },
  [types.SET_LIST_CHECKIN] (state, payload) {
    state.listcheckin = payload.data
    state.pagination = payload.meta.pagination
  }
}
