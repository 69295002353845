export default [
  {
    name: 'users',
    path: '/admin/users',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Usuários',
      icon: 'dvr',
      label: 'Usuários',
      roles: ['Admin']
    }
  }
]
