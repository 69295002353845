import http from '@/http'
const URL = '/admin/settings/event'

export const setting = () => http.get(URL)
export const saveSetting = (payload) => http.post(URL + `/${payload.id}`, payload)

export default {
  setting,
  saveSetting
}
