import services from '../services'
import * as types from './mutation-types'

export const GetSchedules = ({ commit }, params) => (
  services.listSchedules(params).then(res => {
    commit(types.SET_SCHEDULES, res.data)
  })
)

export const GetSchedule = async ({ commit }, id) => {
  await services.getSchedule(id).then(res => {
    commit(types.SET_SCHEDULE, res.data.data)
  })
}

export const SaveSchedule = async ({ commit }, obj) => {
  await services.saveSchedule(obj).then(res => {
    commit(types.SET_SCHEDULE, res.data.data)
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  await services.orderList(obj).then(res => {
    commit(types.SET_SCHEDULES, res.data.data)
    return res.data
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const deleteSchedule = async ({ commit }, obj) => {
  await services.deleteSchedule(obj).then(res => {
    return res
  })
}
