import http from '@/http'
const URL = '/admin/settings/rsvp'

export const rsvp = () => http.get(URL)
export const SaveRSVP = (payload) => http.post(URL + `/${payload.id}`, payload)

export default {
  rsvp,
  SaveRSVP
}
