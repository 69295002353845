import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueApexCharts from 'vue-apexcharts'
import VueToast from 'vue-toast-notification'
import Moment from './modules/moment'
import Pusher from './modules/pusher'
import 'vue-toast-notification/dist/theme-sugar.css'
import App from './App'
import store from './store'
import router from './router'
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import '@/design/index.scss'
import i18n from './i18n'
import VueMask from 'v-mask'
import VueBarcodeScanner from 'vue-barcode-scanner'

Vue.config.productionTip = false
let options = {
  sound: false, // default is false
  soundSrc: '/assets/barcode-scanner-182459.mp3', // default is blank
  sensitivity: 300, // default is 100
  requiredAttr: true, // default is false
  controlSequenceKeys: ['NumLock', 'Clear'], // default is null
  callbackAfterTimeout: true // default is false
}

Vue.use(VueBarcodeScanner, options)
Vue.config.productionTip = false

Vue.use(Pusher)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(Moment)
Vue.use(VueMask)
Vue.use(VueToast, {
  position: 'top'
})
Vue.component('apexchart', VueApexCharts)
Vue.filter('formatTime', (value) => {
  if (value) {
    const parts = value.split(':')
    return parts[0] + ':' + parts[1]
  }
  return 'invalid time'
})
// Init plugin
Vue.use(Loading, {
  color: '#556ee6',
  loader: 'dots',
  canCancel: false,
  isFullPage: false
})

Vue.mixin({
  methods: {
    capitalize: (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(),
    download: (res) => {
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      const url = window.URL.createObjectURL(blob)
      window.open(url, '_blank')
      // let a = document.createElement('a')
      // document.body.appendChild(a)
      // a.style = 'display: none'
      // a.href = url
      // a.download = `relatorio-${Date.now()}.pdf`
      // a.click()
      // window.URL.revokeObjectURL(url)
    },
    iclone (element) {
      // Handle the 3 simple types, and null or undefined
      if (element === null || element === undefined || typeof element !== 'object') {
        return element
      }
      // Handle Date
      if (element instanceof Date) {
        const date = new Date()
        date.setTime(element.getTime())
        return date
      }
      // Handle Array
      if (element instanceof Array) {
        return element.map(this.iclone)
      }
      // Handle Object
      if (element instanceof Object) {
        return Object.keys(element).reduce((accumulate, property) => {
          accumulate[property] = this.iclone(element[property])
          return accumulate
        }, {})
      }
      throw new Error('Unable to copy element! Its type isn\'t supported.')
    }
  }
})

window._Vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
