import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
  }
})

export default {
  [types.SET_SEAT] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.seat = payload
  },
  [types.SET_SEATS] (state, payload) {
    state.seats = payload.data
    state.pagination = payload.meta.pagination
  },
  [types.SET_PARTICIPANTS_SEAT] (state, payload) {
    state.participantsSeat = payload.data
    state.paginationP = { total: payload.total, current_page: payload.current_page }
  }
}
