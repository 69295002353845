import http from '@/http'
const URL = '/admin/forms'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=group,attributes`))
  }
  return one
}

export const showByGroup = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/byGroupId/${id}?include=group,attributes`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}
export const deleteForm = async (id) => http.delete(URL + `/${id}`)
export const deleteField = async (payload) => http.delete(URL + `/${payload.form_id}/field/${payload.id}`)
export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export default {
  list,
  show,
  showByGroup,
  save,
  deleteForm,
  deleteField,
  changeStatus
}
