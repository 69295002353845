export default [
  {
    name: 'forms',
    path: '/settings/forms',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Formulários de Inscrição',
      icon: 'dvr',
      label: 'Formulários de Inscrição',
      roles: ['Admin']
    }
  },
  {
    name: 'forms-edit',
    path: '/settings/forms/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Formulários de Inscrição - Edição',
      icon: 'dvr',
      label: 'Formulários de Inscrição - Edição',
      roles: ['Admin']
    }
  },
  {
    name: 'forms-new',
    path: '/settings/forms/new',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Formulários de Inscrição - Adicionar',
      icon: 'dvr',
      label: 'Formulários de Inscrição - Adicionar',
      roles: ['Admin']
    }
  }
]
