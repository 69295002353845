import { store as auth } from '@/modules/auth'
import { store as settings } from '@/pages/settings'
import { store as settingEvent } from '@/pages/settings/event'
import { store as settingRSVP } from '@/pages/settings/rsvp'
import { store as accreditation } from '@/pages/settings/accreditation'
import { store as users } from '@/pages/admin/users'
import { store as schedules } from '@/pages/settings/schedules'
import { store as speakers } from '@/pages/settings/speakers'
import { store as sponsors } from '@/pages/settings/sponsors'
import { store as emailTemplate } from '@/pages/settings/Email'
import { store as participant } from '@/pages/participants'
import { store as campaign } from '@/pages/wa/campaign'
import { store as template } from '@/pages/settings/wa/templates'
import { store as statusTemplate } from '@/pages/settings/wa/status'
import { store as wa } from '@/pages/settings/wa'
import { store as inep } from '@/pages/ineps'
import { store as guest } from '@/pages/guests'
import { store as checkIn, CHECK_IN_MODULE } from '@/modules/check-in'
import { store as tag } from '@/pages/tags'
import { store as group } from '@/pages/groups'
import { store as seating } from '@/pages/seating'
import { store as form } from '@/pages/forms'
import { store as ticket } from '@/pages/tickets'
import { store as cupon } from '@/pages/cupons'
import { store as settingcheck } from '@/pages/settingscheckin'

export default {
  auth,
  settings,
  settingEvent,
  settingRSVP,
  accreditation,
  users,
  schedules,
  speakers,
  sponsors,
  emailTemplate,
  participant,
  guest,
  campaign,
  template,
  statusTemplate,
  wa,
  inep,
  [CHECK_IN_MODULE]: checkIn,
  tag,
  group,
  seating,
  form,
  ticket,
  cupon,
  settingcheck
}
