import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_SEATS, res.data)
  })
)
export const getAllAParticipants = ({ commit }, params) => (
  services.listParticipants(params).then(res => {
    commit(types.SET_PARTICIPANTS_SEAT, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_SEAT, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveSeat(obj).then(res => {
    commit(types.SET_SEAT, res.data.data)
    return res
  })
}

export const deleteSeat = async ({ commit }, obj) => {
  await services.deleteSeat(obj).then(res => {
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}
