import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

export default {
  [types.SET_SETTING_RSVP] (state, payload) {
    if (payload) {
      state.settingRSVP = payload
      convertIntToBoolean(payload, ['address_undefined', 'local_undefined'])
    }
    state.settingRSVP = { ...state.settingRSVP, ...payload }
  }
}
