export default [
  {
    name: 'cupons',
    path: '/cupons',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Cupons de Descontos',
      icon: 'dvr',
      label: 'Cupons de Descontos',
      roles: ['Admin']
    }
  }
]
