import http from '@/http'
const URL = '/admin/admin-users'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=roles`))
  }
  return one
}

export const saveUser = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}
export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const deleteUser = async (id) => http.delete(URL + `/${id}`)

export default {
  list,
  show,
  saveUser,
  changeStatus,
  deleteUser
}
