export default [
  {
    name: 'reports',
    path: '/reports',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Relatórios',
      icon: 'dvr',
      label: 'Relatórios',
      roles: ['Admin']
    },
    children: [
      { path: '/reports/participants', component: () => import(/* webpackChunkName: "event" */ './participants/Index'), name: 'report-participants', meta: { roles: ['Admin'] } },
      { path: '/reports/participantsAll', component: () => import(/* webpackChunkName: "event" */ './participantsAll/Index'), name: 'report-participantsAll', meta: { roles: ['Admin'] } },
      { path: '/reports/participantsPayments', component: () => import(/* webpackChunkName: "event" */ './participantsPayments/Index'), name: 'report-participantsPayments', meta: { roles: ['Admin'] } },
      { path: '/reports/galistas', component: () => import(/* webpackChunkName: "event" */ './participantsGalistas/Index'), name: 'report-galistas', meta: { roles: ['Admin'] } },
      { path: '/reports/guests', component: () => import(/* webpackChunkName: "event" */ './guests/Index'), name: 'report-guests', meta: { roles: ['Admin'] } }
    ]
  }
]
