import http from '@/http'
const URL = '/admin/tags'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const saveTag = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}
export const deleteTag = async (id) => http.delete(URL + `/${id}`)
export const toogleSetting = (payload) => http.put(`/admin/settings-checkin/checkout`, payload)

export default {
  list,
  show,
  saveTag,
  deleteTag,
  toogleSetting
}
