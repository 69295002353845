import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_CAMPAIGNS, res.data)
  })
)

export const getContacts = ({ commit }, obj) => (
  services.listContacts(obj).then(res => {
    commit(types.SET_CONTACTS, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_CAMPAIGN, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveCampaign(obj).then(res => {
    commit(types.SET_CAMPAIGN, res.data.data)
    return res
  })
}

export const deleteCampaign = async ({ commit }, obj) => {
  await services.deleteCampaign(obj).then(res => {
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}
