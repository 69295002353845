import * as types from './mutation-types'

export default {
  [types.SET_SETTING_WA] (state, payload) {
    if (payload) {
      state.settingWA = payload
    }
    state.settingWA = { ...state.settingWA, ...payload }
  }
}
