import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_TAGS, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_TAG, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveTag(obj).then(res => {
    commit(types.SET_TAG, res.data.data)
    return res
  })
}

export const deleteTag = async ({ commit }, obj) => {
  await services.deleteTag(obj).then(res => {
    return res
  })
}
