import { convertIntToBoolean } from '@/helpers'

export default {
  'SETTINGCHECKIN' (state) {
    if (state.settingcheckin) {
      let setting = convertIntToBoolean(state.settingcheckin, ['has_checkout'])
      return setting
    }
    return {}
  },
  'SETTINGSCHECKIN' (state) {
    return state.settingscheckin
  },
  'LISTCHECKIN' (state) {
    return state.listcheckin
  }
}
