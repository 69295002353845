import http from '@/http'

export type ActivityType = {
  at: Date
  by: string
  isManual: boolean
  text?: string
  isStatusCheckIn?: boolean
  details: string
}

export type HistoricType = {
  id: number
  at: Date
  action: string
  obs: string
}

export type CommentType = {
  id: number
  text: string
  by: string
  at: Date
}

export type Response = {
  id: string
  isStatusCheckIn: boolean
  isStatusCheckOut: boolean
  name: string
  email: string
   // eslint-disable-next-line camelcase
  badge_name: string
  school: string
  codId: string
  tagsId: number[]
  grupo: string
  historico: HistoricType[]
  activitiesList: ActivityType[]
  commentsList: CommentType[]
}

export async function fetchDetails (id: string): Promise<Response> {
  const response = await http.get('/admin/check-in/participants/' + id, {
    params: {
      include: 'tags,activities,activities.operator,historicActivities,group'
    }
  })
  if (response.status !== 200) {
    const exc: any = { CODID: 'P6UNE8GT', response }
    exc['error'] = new Error(exc.CODID)
    throw exc
  }

  const responseComment = await http.get(`/admin/check-in/participants/${id}/comments`)
  if (responseComment.status !== 200) {
    const exc: any = { CODID: 'RNK9G4Q2', responseComment }
    exc['error'] = new Error(exc.CODID)
    throw exc
  }

  const data = response.data.data
  const commentData = responseComment.data.data

  return {
    id: '' + data.id,
    isStatusCheckIn: data.status_check === 1,
    isStatusCheckOut: data.status_check === 2,
    name: data.name,
    email: data.email,
    codId: data.confirmation_code,
    badge_name: data.badge_name,
    school: data.school,
    grupo: data.group.data?.name,
    tagsId: data.tags.data.map((tag: any) => tag.id),
    historico: data.historicActivities.data.map((item: any) => ({
      id: item.id,
      at: new Date(item.created_at),
      action: item.type,
      obs: item.details
    } as HistoricType)),
    activitiesList: data.activities.data.map((item: any) => {
      const activity: ActivityType = {
        at: new Date(item.event_date),
        by: item.operator.data.name,
        details: item.details,
        isManual: false
      }
      if (['CheckedIn', 'CheckedOut'].includes(item.event_type)) {
        activity['isStatusCheckIn'] = item.event_type === 'CheckedIn'
      } else {
        // activity['text'] = 'Observacao'
      }
      return activity
    }),
    commentsList: commentData.map((item: any) => ({
      id: item.id,
      text: item.comment,
      by: item.operator.name,
      at: new Date(item.created_at)
    }))
  }
}
