import services from '../services'
import * as types from './mutation-types'

export const list = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_SPONSORS, res.data)
  })
)

export const show = async ({ commit, dispatch }, id) => {
  await services.find(id).then(res => {
    commit(types.SET_SPONSOR, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_SPONSOR, res.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  await services.orderList(obj).then(res => {
    commit(types.SET_SPONSORS, res.data)
    return res.data
  })
}

export const destroy = async ({ commit }, obj) => {
  await services.deletePartner(obj).then(res => {
    return res
  })
}
