export default [
  {
    name: 'wa-template',
    path: '/wa/template',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Templates',
      icon: 'dvr',
      label: 'Templates',
      roles: ['Admin']
    }
  }
]
