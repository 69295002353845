export default [
  {
    name: 'setting-accreditation',
    path: '/settings/accreditation',
    component: () => import(/* webpackChunkName: "event" */ './Accreditation'),
    meta: {
      showNavbar: true,
      title: 'Parâmetros do Credenciamento',
      icon: 'dvr',
      label: 'Configurações gerais',
      roles: ['Admin']
    }
  }
]
