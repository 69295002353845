import http from '@/http'
const URL = '/admin/cupons'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const saveCupom = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}
export const deleteCupom = async (id) => http.delete(URL + `/${id}`)
export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const exportCupom = async (params) => {
  const data = (await http.get(URL + '/export', { params: params, responseType: 'blob' })).data
  return data
}
export default {
  list,
  show,
  saveCupom,
  deleteCupom,
  changeStatus,
  exportCupom
}
