const convertIntToBoolean = (obj, arrayFields) => {
  if (obj) {
    arrayFields.forEach(res => {
      if (obj.hasOwnProperty(res)) obj[res] = parseInt(obj[res]) === 1
    })
  }
  return obj
}

const convertBooleanToInt = (obj, arrayFields) => {
  if (obj) {
    arrayFields.forEach(res => {
      if (obj.hasOwnProperty(res)) {
        obj[res] = obj[res] ? 1 : 0
      }
    })
  }
  return obj
}

export {
  convertIntToBoolean,
  convertBooleanToInt
}
