import services from '../services'
import * as types from './mutation-types'

export const GetSpeakers = ({ commit }, params) => (
  services.listSpeakers(params).then(res => {
    commit(types.SET_SPEAKERS, res.data)
    return res.data
  })
)

export const GetSpeaker = async ({ commit }, id) => {
  await services.getSpeaker(id).then(res => {
    commit(types.SET_SPEAKER, res.data.data)
  })
}

export const SaveSpeaker = async ({ commit }, obj) => {
  await services.saveSpeaker(obj).then(res => {
    commit(types.SET_SPEAKER, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  await services.orderList(obj).then(res => {
    commit(types.SET_SPEAKERS, res.data.data)
    return res.data
  })
}

export const deleteSpeaker = async ({ commit }, obj) => {
  await services.deleteSpeaker(obj).then(res => {
    return res
  })
}
