import services from '../services'
import * as types from './mutation-types'

export const GetSetting = ({ commit }) => (
  services.setting().then(res => {
    commit(types.SET_SETTING, res.data.data)
  })
)

export const LoadOnceSetting = ({ state, dispatch }) => {
  if (isObjectEmpty(state.setting)) {
    dispatch('GetSetting')
  }
}

export const GetStatusList = ({ commit }, params) => (
  services.status(params).then(res => {
    console.log(res.data)
    commit(types.SET_STATUSLIST, res.data)
  })
)

export const SaveSetting = async ({ commit }, obj) => {
  await services.saveSetting(obj).then(res => {
    commit(types.SET_SETTING, res.data.data)
    return res
  })
}

function isObjectEmpty (o) {
  if (typeof o === 'object') {
    return Object.values(o).length === 0
  }
  return true
}
