const ROLE_ADMIN = 'Admin'
const ROLE_MASTER = 'Master'
const ROLE_OPERATOR = 'Operator'
const ROLE_VIEWER = 'Viewer'

export const hasToken = ({ token }) => !!token

export const isAdmin = state => state?.user?.role === ROLE_ADMIN
export const isMaster = state => state?.user?.role === ROLE_MASTER
export const isOperator = state => state?.user?.role === ROLE_OPERATOR
export const isViewer = state => state?.user?.role === ROLE_VIEWER
export const userRole = state => state?.user?.role
