import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    name: '',
    avatar: ''
  }
})

export default {
  [types.SET_PARTICIPANT] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.participant = payload
  },
  [types.SET_LOGTRANSACTION] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.logtransaction = payload
  },
  [types.SET_PARTICIPANTS] (state, payload) {
    state.participants = payload.data
    state.pagination = payload.meta.pagination
  }
}
