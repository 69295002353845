import http from '@/http'
const URL = '/admin/reports'

export const listParticipants = async (params) => {
  const list = (await http.get(URL + '/participants?include=ref,transports,contact,companion,attachments,group', { params: params })).data
  return list
}

export const listGalistas = async (params) => {
  const list = (await http.get(URL + '/participantsGalistas?include=ref,transports,contact,companion,attachments,group', { params: params })).data
  return list
}

export const listParticipantsPayments = async (params) => {
  const list = (await http.get(URL + '/participantsPayments?include=orders.ticket,orders.cupom,orders.transactions,orders.transactionsPix,contacts,group', { params: params })).data
  return list
}

export const exportParticipants = async (params) => {
  const data = (await http.get(URL + '/participants/export', { params: params, responseType: 'blob' })).data
  return data
}

export const exportGalistasAll = async (params) => {
  const data = (await http.get(URL + '/participants/exportGalistasAll', { params: params, responseType: 'blob' })).data
  return data
}

export const exportParticipantsAll = async (params) => {
  const data = (await http.get(URL + '/participants/exportAll', { params: params, responseType: 'blob' })).data
  return data
}

export const exportParticipantsPayments = async (params) => {
  const data = (await http.get(URL + '/participants/exportPaymentAll', { params: params, responseType: 'blob' })).data
  return data
}

export const listGuests = async (params) => {
  const list = (await http.get(URL + '/guests', { params: params })).data
  return list
}

export const exportGuests = async (params) => {
  const data = (await http.get(URL + '/guests/export', { params: params, responseType: 'blob' })).data
  return data
}
export default {
  exportParticipantsPayments,
  listParticipantsPayments,
  listParticipants,
  exportParticipants,
  exportParticipantsAll,
  exportGalistasAll,
  listGuests,
  listGalistas,
  exportGuests
}
