import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    name: '',
    avatar: ''
  }
})

export default {
  [types.SET_ADMIN_USER] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.admin_user = payload
  },
  [types.SET_ADMIN_USERS] (state, payload) {
    state.admin_users = payload.data
    state.pagination = payload.meta.pagination
  }
}
