import { routes as auth } from '../modules/auth'
import { routes as home } from '../pages/home'
import { routes as settings } from '../pages/settings'
import { routes as settingsEvent } from '../pages/settings/event'
import { routes as settingsRSVP } from '../pages/settings/rsvp'
import { routes as accreditation } from '../pages/settings/accreditation'
import { routes as users } from '../pages/admin/users'
import { routes as schedules } from '../pages/settings/schedules'
import { routes as speakers } from '../pages/settings/speakers'
import { routes as sponsors } from '../pages/settings/sponsors'
import { routes as groups } from '../pages/groups'
import { routes as forms } from '../pages/forms'
import { routes as emailTemplate } from '../pages/settings/Email'
import { routes as participant } from '../pages/participants'
import { routes as campaign } from '../pages/wa/campaign'
import { routes as template } from '../pages/settings/wa/templates'
import { routes as wa } from '../pages/settings/wa'
import { routes as inep } from '../pages/ineps'
import { routes as guest } from '../pages/guests'
import { routes as tag } from '../pages/tags'
import { routes as seating } from '../pages/seating'
import { routes as ticket } from '../pages/tickets'
import { routes as cupon } from '../pages/cupons'
import { routes as checkIn } from '@/modules/check-in'
import { routes as report } from '../pages/reports'
import { routes as settingcheck } from '../pages/settingscheckin'

export default [
  ...auth,
  ...home,
  ...settings,
  ...settingsEvent,
  ...settingsRSVP,
  ...accreditation,
  ...users,
  ...schedules,
  ...speakers,
  ...sponsors,
  ...emailTemplate,
  ...groups,
  ...forms,
  ...participant,
  ...campaign,
  ...template,
  ...wa,
  ...guest,
  ...inep,
  ...checkIn,
  ...seating,
  ...tag,
  ...ticket,
  ...cupon,
  ...report,
  ...settingcheck
]
