import http from '@/http'

export async function fetchTags () {
  const response = await http.get('/admin/tags')
  if (response.status !== 200) {
    const exc: any = { CODID: 'I8EEC0T9', response }
    exc['error'] = new Error(exc.CODID)
    throw exc
  }

  const tags = response.data.data

  return tags.map((tag: any) => ({
    id: tag.id,
    text: tag.name
  }))
}
