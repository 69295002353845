export default [
  {
    name: 'site-sponsors',
    path: '/settings/site/sponsors',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Parceiros',
      icon: 'dvr',
      label: 'Parceiros',
      roles: []
    }
  },
  {
    name: 'site-sponsor-edit',
    path: '/settings/site/sponsors/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Parceiro - Edição',
      icon: 'dvr',
      label: 'Parceiro - Edição',
      roles: []
    }
  },
  {
    name: 'site-sponsor-show',
    path: '/settings/site/sponsors/:id/details',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Parceiro',
      icon: 'dvr',
      label: 'Parceiro',
      roles: []
    }
  },
  {
    name: 'site-sponsor-new',
    path: '/settings/site/sponsors/novo',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Parceiro - Adicionar',
      icon: 'dvr',
      label: 'Parceiro - Adicionar',
      roles: []
    }
  }
]
