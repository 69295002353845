export const CHECK_IN_PAGE_INDEX = 'CheckIn/Index'
export const CHECK_IN_PAGE_INFO = 'CheckIn/Info'
export const CHECK_IN_PAGE_DETAIL = 'CheckIn/Detail'
export const CHECK_IN_PAGE_SCAN = 'CheckIn/Scan'

export default [
  {
    path: '/check-in',
    component: () => import('@/modules/check-in/pages/check-in'),
    meta: {
      showNavbar: true,
      title: 'Check-In',
      label: 'Check-In',
      roles: ['Admin', 'Master', 'Operator']
    },
    children: [
      {
        name: CHECK_IN_PAGE_INDEX,
        path: '',
        component: () => import('@/modules/check-in/pages/index/index'),
        meta: {
          roles: ['Admin', 'Master', 'Operator']
        }
      },
      {
        name: CHECK_IN_PAGE_SCAN,
        path: 'scan',
        component: () => import('@/modules/check-in/pages/scan/scan'),
        meta: {
          roles: ['Admin', 'Master', 'Operator']
        }
      }
    ]
  }
]
