import http from '@/http'
const URL = '/admin/schedules'

export const listSchedules = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const getSchedule = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const saveSchedule = (payload) => {
  if (!payload.id) {
    return http.post(URL, payload)
  }
  return http.put(URL + `/${payload.id}`, payload)
}

export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const deleteSchedule = async (id) => http.delete(URL + `/${id}`)
export const orderList = async (payload) => http.put(URL + `/sequence`, payload)

export default {
  listSchedules,
  getSchedule,
  saveSchedule,
  changeStatus,
  deleteSchedule,
  orderList
}
