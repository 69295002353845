import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_TICKETS, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_TICKET, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveTicket(obj).then(res => {
    commit(types.SET_TICKET, res.data.data)
    return res
  })
}

export const deleteTicket = async ({ commit }, obj) => {
  await services.deleteTicket(obj).then(res => {
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}
