import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
  }
})

export default {
  [types.SET_TEMPLATE] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.template = payload
  },
  [types.SET_TEMPLATES] (state, payload) {
    state.templates = payload.data
    state.pagination = { total: payload.total, current_page: payload.current_page }
  }
}
