export default {
  'CAMPAIGN' (state) {
    return state.campaign
  },
  'CAMPAIGNS' (state) {
    return state.campaigns
  },
  'CONTACTS' (state) {
    return state.contacts
  }
}
