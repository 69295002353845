export default [
  {
    name: 'site-schedules',
    path: '/settings/site/schedules',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Programação',
      icon: 'dvr',
      label: 'Programação',
      roles: []
    }
  },
  {
    name: 'site-schedule-edit',
    path: '/settings/site/schedules/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Programação - Edição',
      icon: 'dvr',
      roles: [],
      label: 'Programação - Edição'
    }
  },
  {
    name: 'site-schedule-new',
    path: '/settings/site/schedules/novo',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Programação - Adicionar',
      roles: [],
      icon: 'dvr',
      label: 'Programação - Adicionar'
    }
  }
]
