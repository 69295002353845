import http from '@/http'
const URL = '/admin/settings/accreditation'

export const accreditation = () => http.get(URL)
export const SaveAccreditation = (payload) => http.post(URL + `/${payload.id}`, payload)

export default {
  accreditation,
  SaveAccreditation
}
