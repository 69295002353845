export default [
  {
    name: 'site-speakers',
    path: '/settings/site/speakers',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Palestrantes',
      icon: 'dvr',
      label: 'Palestrantes',
      roles: []
    }
  },
  {
    name: 'site-speaker-edit',
    path: '/settings/site/speakers/:id/edit',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Palestrante - Edição',
      icon: 'dvr',
      label: 'Palestrante - Edição',
      roles: []
    }
  },
  {
    name: 'site-speaker-new',
    path: '/settings/site/speakers/novo',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Palestrante - Adicionar',
      icon: 'dvr',
      label: 'Palestrante - Adicionar',
      roles: []
    }
  }
]
